import Markdown from "../../../utilities/Markdown";
import Unslider from "../../../utilities/Unslider";
import BlockScroller from "../BlockScroller";
import {buildCssUrlString} from "../../../../utils/StringUtilities";
import styles from './styles/MessageBlock.module.scss';
import classNames from 'classnames';
import ErrorBlock from "../ErrorBlock";
import {getCdnUrl} from "../../../../utils/SchoolBlocksUtilities";
import ClickableLink from "../../../utilities/ClickableLink";
import {isJson} from "../../../admin/spinup/utilities";
import {IBlockComponentProps} from "../_Block";
import {PropsWithChildren, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import ImageComponent from "../../../utilities/ImageComponent";

const slideClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

function MessageLinkContainer(props: PropsWithChildren<{
    href: string,
    title?: string,
}>) {
    const {href, title, children} = props;

    if (href) {
        return <ClickableLink href={href} title={title}>
            {children}
        </ClickableLink>
    } else {
        return <>{children}</>
    }
}

export const MessageBlockColors = {
    LIGHT: "light",
    DARK: "dark",
}

interface IMessageBlockProps extends IBlockComponentProps {
    blockObj: IMessageBlockObj,
}

function MessageSlide(props: {
    in: boolean,
    item: IMessageItem,
    blockRef: IBlockComponentProps["blockRef"],
    blockObj: IMessageBlockObj,
    htmlId: IBlockComponentProps["htmlId"],
    outsideGrid: IBlockComponentProps["outsideGrid"],
    setShowExpandButton: IBlockComponentProps["setShowExpandButton"],
    scrollbarColor: string,
    colorCSS: string,
}) {
    const nodeRef = useRef(null);

    const hasTitle = !!props.item.title;
    const hasImage = !!props.item.image;
    const hasMessage = !!props.item.message;
    const hasAltText = !!props.item.altText;
    const altText = hasAltText ? props.item.altText : "";
    const hasMessageUrl = !!props.item.messageUrl;
    const hideFromAssistiveTech = (!hasTitle && !hasMessage && hasImage && !hasAltText)
    const imageUrl = getCdnUrl(props.item.image);

    const blockScrollerClassName = classNames({
        ['sb-message-slider']: true,
        [props.colorCSS]: true,
        [styles.blockScroller]: true,
    })

    return <CSSTransition in={props.in} classNames={slideClassNames} timeout={1000} nodeRef={nodeRef}>
        <div className={styles.slide} style={{visibility: props.in ? "visible" : "hidden"}} ref={nodeRef}>
            <BlockScroller blockRef={props.blockRef}
                           blockObj={props.blockObj}
                           htmlId={props.htmlId}
                           setShowExpandButton={props.setShowExpandButton}
                           outsideGrid={props.outsideGrid}
                           color={props.scrollbarColor}
                           aria-hidden={hideFromAssistiveTech}
                           className={blockScrollerClassName}>
                {(hasImage && !!props.item.displayTextUnderImage) ?
                    <MessageLinkContainer href={props.item.messageUrl} title={props.item.title}>
                        <div>
                            {hasTitle && <div className={`sb-msgHead`}>
                                <span>{props.item.title}</span>
                            </div>}
                            <ImageComponent className="sb-message-block-image" alt={altText}
                                            src={encodeURI(imageUrl)} style={{"position": "relative"}}/>
                            {hasMessage && <div className="sb-blockContent ">
                                <Markdown allowLinks={!hasMessageUrl} withStyles={true} source={props.item.message}/>
                            </div>}
                        </div>
                    </MessageLinkContainer> :
                    <MessageLinkContainer href={props.item.messageUrl} title={props.item.title}>
                        <div>
                            {hasImage &&
                                <div className="sb-message-block-image sb-message-block-background-image"
                                     role="img" aria-label={altText} title={altText}
                                     style={{"backgroundImage": buildCssUrlString(imageUrl)}}/>}
                            {hasTitle && <div className={`sb-msgHead`}>
                                <span>{props.item.title}</span>
                            </div>}
                            {hasMessage && <div className="sb-blockContent ">
                                <Markdown allowLinks={!hasMessageUrl} withStyles={true} source={props.item.message}/>
                            </div>}
                        </div>
                    </MessageLinkContainer>}
            </BlockScroller>
        </div>
    </CSSTransition>
}

export default function MessageBlock(props: IMessageBlockProps) {
    const [currentUnsliderIndex, setCurrentUnsliderIndex] = useState(0);

    try {
        // in PHP the json_data we get back is a string... but it sends jsonObj, so set that if it exists.
        let json_data = props.blockObj.blockModel.json_data;
        if (typeof json_data === 'string' && isJson(json_data)) json_data = JSON.parse(json_data);

        let items = json_data.block.settings.items;
        if (!Array.isArray(items)) {
            // handle legacy format
            items = Object.values(items);
        }

        items = items.map(item => ({
            ...item,
            displayTextUnderImage: !!item.displayTextUnderImage, // not sure if necessary anymore, but ported from PHP logic
        }));
        const url = json_data.block.settings.url;
        const scrollbarColor = json_data.block.settings.color === MessageBlockColors.DARK ? "#FFF" : "rgba(0, 0, 0, .4)";
        const colorCSS = json_data.block.settings.color === MessageBlockColors.DARK ? "sb-organization-color-block-bg" : "sb-organization-color-block-font";

        let body;
        if (items.length === 1) {
            const item = items[0];
            const hasTitle = !!item.title;
            const hasImage = !!item.image;
            const hasMessage = !!item.message;
            const hasAltText = !!item.altText;
            const altText = hasAltText ? item.altText : "";
            const hasMessageUrl = !!item.messageUrl;
            const displayTextUnderImage = item.displayTextUnderImage;
            const hideFromAssistiveTech = (!hasTitle && !hasMessage && hasImage && !hasAltText)
            const imageUrl = getCdnUrl(item.image);

            const singleBlockScrollerClassName = classNames({
                [colorCSS]: true,
                [styles.blockScroller]: true,
            })

            body = <BlockScroller blockRef={props.blockRef}
                                  blockObj={props.blockObj}
                                  htmlId={props.htmlId}
                                  setShowExpandButton={props.setShowExpandButton}
                                  outsideGrid={props.outsideGrid}
                                  color={scrollbarColor}
                                  aria-hidden={hideFromAssistiveTech}
                                  className={singleBlockScrollerClassName}>
                {(hasImage && displayTextUnderImage) ?
                    <MessageLinkContainer href={item.messageUrl} title={item.title}>
                        {hasTitle && <div className={`sb-blockHead ${hasImage ? "" : colorCSS}`} role="heading"
                                          data-blocktype={props.blockObj.blockType}>
                            <span>{item.title}</span>
                        </div>}
                        <ImageComponent className="sb-message-block-image" alt={altText}
                             src={imageUrl} style={{"position": "relative"}}/>
                        {hasMessage && <div className="sb-blockContent ">
                            <Markdown allowLinks={!hasMessageUrl} withStyles={true} source={item.message}/>
                        </div>}
                    </MessageLinkContainer> :
                    <MessageLinkContainer href={item.messageUrl} title={item.title}>
                        {hasImage && <div className="sb-message-block-image sb-message-block-background-image"
                                          role="img" aria-label={altText} title={altText}
                                          style={{"backgroundImage": buildCssUrlString(imageUrl)}}/>}
                        {hasTitle && <div className={`sb-blockHead ${hasImage ? "" : colorCSS}`}
                                          data-blocktype={props.blockObj.blockType}>
                            <span>{item.title}</span>
                        </div>}
                        {hasMessage && <div className="sb-blockContent ">
                            <Markdown allowLinks={!hasMessageUrl} withStyles={true} source={item.message}/>
                        </div>}
                    </MessageLinkContainer>}
            </BlockScroller>
        } else {
            body = <div className={`${colorCSS}`} data-blocktype={props.blockObj.blockType}>
                <Unslider
                    delay={5000}
                    blockId={props.blockObj.id}
                    containerClassName={styles.unsliderContainer}
                    currentIndex={currentUnsliderIndex}
                    setCurrentIndex={setCurrentUnsliderIndex}
                    controlsStyles={styles.mobilePositionControls}
                >
                    {items && items.map((item, i) => {
                        return <MessageSlide
                            key={i}
                            in={i === currentUnsliderIndex}
                            item={item}
                            htmlId={props.htmlId}
                            blockRef={props.blockRef}
                            blockObj={props.blockObj}
                            outsideGrid={props.outsideGrid}
                            setShowExpandButton={props.setShowExpandButton}
                            scrollbarColor={scrollbarColor}
                            colorCSS={colorCSS}
                        />
                    })}
                </Unslider>
            </div>
        }

        return <div id={`sb-block-message-${props.blockObj.id}`} className={`sb-block-message ${url ? 'sb-url-block' : ''}`}
                    style={{"position": "static"}} data-target={url}>
            {body}
        </div>
    } catch (error) {
        return <ErrorBlock blockObj={{errorMessage: error}} />
    }
}
